<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <material-card
          :title="getModalTitle()"
          tile
          color="green"
          full-width
        >
          <div style="text-align: end">
            <v-btn
              small
              color="error darken-1"
              @click="dialog = false"
            >{{ $t('common.close') }}
            </v-btn>
          </div>
          <v-divider
            v-if="productsList.length"
            style="margin: 0"/>
          <div
            v-for="(item, index) in productsList"
            :key="item+index"
          >
            <v-layout
              wrap
              style="padding: 5px 0">
              <v-flex
                xs3
                sm3
                md2
                style="text-align: center; align-self: center; padding-right: 5px">
                <img
                  :src="item.image !== null && item.image !== '' ? item.image : noImg"
                  style="border: 2px solid lightgray; max-width: 100px; max-height: 100px; width: 100%;">
              </v-flex>
              <v-flex>
                <div>
                  <div>
                    <span><small><strong>{{ item.orderCode }}</strong></small></span>
                    <span style="float: right"><small>{{ item.purchaseDate }}</small></span>
                  </div>
                  <div>
                    <span>{{ item.product }}</span>
                  </div>
                  <div>
                    <span>{{ $t('evoucher.quantity') }}: {{ item.quantity }}</span>
                  </div>
                  <div>
                    <span>{{ $t('infoVoucher.unitPrice') }}: <strong>{{ item.price }}</strong> {{ currencyUnit }}</span>
                  </div>
                  <div v-if="item.promotionValue !== null">
                    <span v-if="item.promotionType===voucherProductPromotionType.PRICE">{{ $t('cart.promotion.discount') }}:
                      <strong>{{ formatMoney(item.promotionValue) }}</strong> {{ currencyUnit }}
                    </span>
                    <span v-else>{{ $t('cart.promotion.discount') }}:
                      <strong>{{ item.promotionValue }}</strong> % ({{ $t('evoucher.discountPrice') }} {{ formatMoney(item.promotionPrice) + ' VND' }})
                    </span>
                  </div>
                  <div>
                    <span>{{ $t('thirdParty.total') }}: <strong>{{ item.totalPrice }}</strong> {{ currencyUnit }}</span>
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <v-divider
              v-if="index < productsList.length - 1"
              style="margin: 0"/>
          </div>
          <div>
            <div class="py-2">
              <table
                class="table m-0"
                style="margin: 0">
                <tbody>
                  <tr>
                    <td
                      class="text-right order-footer-title">
                      {{ $t('productOrder.tableList.header.totalPrice') }}
                    </td>
                    <td
                      class="footer-cell-price text-right">{{ formatMoney(totalPrice) + " VND" }}</td>
                  </tr>
                  <tr>
                    <td
                      class="text-right order-footer-title">
                      {{ $t('paymentMethod.discountOnBill') }}
                    </td>
                    <td
                      class="footer-cell-price text-right">{{ formatMoney(totalPromotionOrderPrice) + " VND" }}</td>
                  </tr>
                  <tr>
                    <td
                      class="text-right order-footer-title">
                      {{ $t('cart.promotion.totalPriceToPay') }}
                    </td>
                    <td class="footer-cell-price text-right">{{ finalTotalPrice + " VND" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </material-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import functionUtils from 'utils/functionUtils'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
export default {
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    productHeaders: [
      { text: '#', value: 'stt' },
      { text: 'productOrder.detail.image', value: 'image' },
      { text: 'productOrder.tableList.header.product', value: 'product' },
      {
        text: 'productOrder.detail.price',
        value: 'price'
      },
      { text: 'productOrder.detail.quantity', value: 'quantity' },
      { text: 'productOrder.tableList.header.totalPrice', value: 'totalPrice' }
    ],
    productsList: [],
    orderCode: null,
    fromAdmin: false,
    userOrder: null,
    deliveryInfo: null,
    noImg: require('../../assets/noimage.png'),
    totalPrice: 0,
    voucherProductPromotionType: VoucherProductPromotionType,
    currencyUnit: 'VND',
    totalPromotionOrderPrice: 0,
    finalTotalPrice: 0
  }),
  methods: {
    onShowModalOrderDetail: function (product, fromAdmin) {
      this.deliveryInfo = null
      this.fromAdmin = fromAdmin
      let data = product.productsList
      this.orderCode = product.orderCode
      if (fromAdmin) {
        this.userOrder = product.userOrder
        this.userOrder['fullname'] = functionUtils.concatFullname(this.userOrder.last_name, this.userOrder.first_name)
      }
      this.deliveryInfo = product.deliveryInfo
      this.productsList = []
      this.totalPrice = 0
      for (let i = 0, size = data.length; i < size; i++) {
        let price = !functionUtils.isEmptyString(data[i].price)
          ? data[i].price : data[i].workpiece.value
        let totalVoucherPrice = price * data[i].quantity
        let promotionValue = data[i].product_promotion && data[i].product_promotion.value
        let promotionType = data[i].product_promotion && data[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        let totalPrice = totalVoucherPrice - promotionPrice
        totalPrice = totalPrice > 0 ? totalPrice : 0
        this.totalPrice += totalPrice
        let obj = {
          stt: i + 1,
          product: data[i].workpiece.name,
          price: functionUtils.convertFormatNumberToMoney(price.toString()),
          quantity: data[i].quantity,
          totalPrice: functionUtils.convertFormatNumberToMoney((totalPrice).toString()),
          image: data[i].workpiece.image_url,
          promotionValue: !functionUtils.isEmptyString(promotionValue) ? promotionValue : null,
          promotionType: promotionType,
          promotionPrice: promotionPrice
        }
        this.productsList.push(obj)
      }
      this.totalPrice = this.totalPrice > 0 ? this.totalPrice : 0
      this.totalPromotionOrderPrice = product.totalPromotionOrderPrice
      this.finalTotalPrice = product.totalPrice
      this.dialog = true
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    getModalTitle: function () {
      return this.$t('productOrder.order') + ' ' + this.orderCode + ' ' + this.$t('productOrder.detail')
    },
    getPromotionPriceVoucher: function (voucherPrice, promotionValue, promotionType) {
      if (promotionType === VoucherProductPromotionType.PRICE) {
        return promotionValue
      } else if (promotionType === VoucherProductPromotionType.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.order-footer-title {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.footer-cell-price {
  white-space: nowrap;
}
</style>
