<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-row justify="center">
      <v-col
        cols="12"
        md="12">
        <h3 class="mt-0">{{ $t("productOrder.myOrderTitle") }}</h3>
        <div
          v-for="(item, index) in desserts"
          :key="item+index"
        >
          <v-layout
            wrap
            style="padding: 5px 0">
            <v-flex
              xs3
              sm3
              md2
              style="text-align: center; align-self: center; padding-right: 5px"
              @click="onViewDetail(item)">
              <img
                :src="item.imageUrl !== null && item.imageUrl !== '' ? item.imageUrl : noImg"
                style="border: 2px solid lightgray; max-width: 100px; max-height: 100px; width: 100%;">
            </v-flex>
            <v-flex>
              <div @click="onViewDetail(item)">
                <div>
                  <span><small><strong>{{ item.orderCode }}</strong></small></span>
                  <span style="float: right"><small>{{ item.purchaseDate }}</small></span>
                </div>
                <div>
                  <span>{{ item.voucherName }}</span>
                </div>
                <div>
                  <span>{{ $t('evoucher.quantity') }}: {{ item.totalQuantity }}</span> |
                  <span><strong>{{ item.totalPrice }}</strong> {{ currencyUnit }}</span>
                </div>
              </div>
              <div>
                <v-chip
                  :color="getColor(item.status)"
                  dark
                  small>
                  {{ $t(getStatus(item.status)) }}
                </v-chip>
                <v-icon
                  v-show="
                    item.status != productOrderStatusEnum.CANCEL &&
                      item.status != productOrderStatusEnum.DELIVERED &&
                      item.status != productOrderStatusEnum.PAID_ONLINE
                  "
                  medium
                  color="error"
                  style="float: right"
                  @click="onDelete(item)"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-flex>
          </v-layout>
          <v-divider style="margin: 0"/>
        </div>
        <div class="text-center pt-2">
          <pagination
            :total="paginate.totalPage"
            :current-page="paginate.currentPage"
            :row-per-page="paginate.rowPerPage"
            @onPageChange="onPageChange"
          />
        </div>
      </v-col>
    </v-row>
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmCancelOrder"
    />
    <orderDetailModal ref="orderDetailModal" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import dateUtils from 'utils/dateUtils'
import ProductOrderStatusEnum from 'enum/productOrderStatusEnum'
import VoucherProductApplyType from 'enum/voucherProductApplyType'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import StoreChannelType from 'enum/storeChannelType'
import ConfirmModal from 'Components/ConfirmModal'
import OrderDetailModal from './OrderDetailModal.vue'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  components: {
    ConfirmModal,
    OrderDetailModal
  },
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: '#', value: 'stt' },
        { text: 'productOrder.tableList.header.orderCode', value: 'orderCode' },
        {
          text: 'productOrder.tableList.header.purchaseDate',
          value: 'purchaseDate'
        },
        { text: 'productOrder.tableList.header.product', value: 'product' },
        {
          text: 'productOrder.tableList.header.totalPrice',
          value: 'totalPrice'
        },
        { text: 'productOrder.tableList.header.status', value: 'status' },
        { text: 'paymentMethod.title', value: 'paymentMethod' },
        { text: 'common.actions', value: 'actions' }
      ],
      desserts: [],
      confirmModalTitle: '',
      orderProductId: null,
      productOrderStatusEnum: {
        CANCEL: ProductOrderStatusEnum.CANCEL,
        DELIVERED: ProductOrderStatusEnum.DELIVERED,
        PAID_ONLINE: ProductOrderStatusEnum.PAID_ONLINE
      },
      isLoading: false,
      paginate: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      },
      noImg: require('../../assets/noimage.png'),
      externalUserId: null,
      storeChannelId: null,
      agentDefaultId: null,
      currencyUnit: 'VND'
    }
  },
  async created () {
    await this.getSettingConfig()
    let routeName = this.$route.name
    if (routeName === 'LienVietMyOrderList') {
      let viVietSession = sessionStorage.getItem('viVietSession')
      viVietSession = JSON.parse(viVietSession)
      let userId = viVietSession[4]
      this.externalUserId = userId
      this.storeChannelId = StoreChannelType.LIEN_VIET_POST_BANK
      this.getProductOrderNotLogin()
    } else {
      this.externalUserId = null
      this.storeChannelId = this.storeChannelsIdInRouteParams()
      await this.getProductOrder()
    }
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    onViewDetail: function (product) {
      this.$refs.orderDetailModal.onShowModalOrderDetail(product)
    },
    /**
     * Get color
     */
    getColor (status) {
      if (status === ProductOrderStatusEnum.CANCEL) return 'red'
      else if (status === ProductOrderStatusEnum.WAIT_FOR_PAY) return 'orange'
      else return 'green'
    },
    /**
     * Get product order
     */
    getProductOrder: async function () {
      let filter = {
        params: {
          customerFilter: true,
          storeChannelId: this.getStoreChannelId(),
          userId: this.externalUserId
        }
      }
      this.isLoading = true
      await this.GET_PRODUCT_ORDER(filter).then(
        function (res) {
          let data = res.data
          this.desserts = []
          for (let i = 0, size = data.length; i < size; i++) {
            let promotionCode = data[i].promotion_code
            let promotionOrderList = promotionCode.filter(
              x => x.apply_type === VoucherProductApplyType.ORDER)
            let productList = data[i].items
            let totalPrice = this.getTotalPrice(productList)
            let totalPromotionOrderPrice = this.getPromotionPriceOrder(totalPrice, promotionOrderList)
            let finalPrice = totalPrice - totalPromotionOrderPrice
            finalPrice = finalPrice > 0 ? finalPrice : 0
            let obj = {
              stt: i + 1,
              id: data[i].id,
              orderCode: data[i].code,
              purchaseDate: dateUtils.formatDate(
                data[i].date_created,
                dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
                dateUtils.STATIC_FORMAT_DATE_TIME
              ),
              product: 'Voucher',
              totalPrice: functionUtils.convertFormatNumberToMoney(finalPrice.toString()),
              status: data[i].status,
              productsList: productList,
              deliveryInfo: data[i].delivery_info,
              paymentMethod: this.$t(functionUtils.paymentMethodTypeName(data[i].payment_method)),
              imageUrl: data[i].items[0].workpiece.image_url,
              voucherName: data[i].items[0].workpiece.name,
              totalQuantity: this.getTotalQuantity(data[i].items),
              totalPromotionOrderPrice: totalPromotionOrderPrice
            }
            this.desserts.push(obj)
          }
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Get status
     */
    getStatus: function (status) {
      if (status === ProductOrderStatusEnum.DELIVERED) {
        return 'productOrder.tableList.status.delivered'
      } else if (status === ProductOrderStatusEnum.WAIT_FOR_PAY) {
        return 'productOrder.tableList.status.waitForPay'
      } else if (status === ProductOrderStatusEnum.CANCEL) {
        return 'productOrder.tableList.status.cancel'
      } else if (status === ProductOrderStatusEnum.PAID_ONLINE) {
        return 'productOrder.tableList.status.paid'
      }
    },
    /**
     * Get total price
     */
    getTotalPrice: function (items) {
      let total = 0
      for (let i = 0, size = items.length; i < size; i++) {
        let price = !functionUtils.isEmptyString(items[i].price)
          ? items[i].price : items[i].workpiece.value
        let totalVoucherPrice = price * items[i].quantity
        let promotionValue = items[i].product_promotion && items[i].product_promotion.value
        let promotionType = items[i].product_promotion && items[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        total += totalVoucherPrice - promotionPrice
      }
      return total
    },
    /**
     * Get total quantity
     */
    getTotalQuantity: function (items) {
      let total = 0
      for (let i = 0, size = items.length; i < size; i++) {
        total += items[i].quantity
      }
      return total
    },
    /**
     * Edit
     */
    onEdit: function () {
    },
    /**
     * Delete
     */
    onDelete: function (item) {
      this.orderProductId = item.id
      this.confirmModalTitle = this.$t('confirmModal.confirmCancelOrder')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Cancel order
     */
    onCancelOrder: function () {
      let filter = {
        id: this.orderProductId
      }
      this.CANCEL_VOUCHER_PRODUCT_ORDER(filter).then(
        function () {
          this.getProductOrder()
        }.bind(this)
      )
    },
    confirmCancelOrder: function () {
      if (this.externalUserId) {
        this.onCancelOrderNotLogin()
      } else {
        this.onCancelOrder()
      }
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
    },
    //
    /**
     * Get product order
     */
    getProductOrderNotLogin: function () {
      let filter = {
        params: {
          storeChannelId: this.storeChannelId,
          userId: this.externalUserId
        }
      }
      this.isLoading = true
      this.GET_PRODUCT_ORDER_NOT_LOGIN(filter).then(
        function (res) {
          let data = res.data
          this.desserts = []
          for (let i = 0, size = data.length; i < size; i++) {
            let obj = {
              stt: i + 1,
              id: data[i].id,
              orderCode: data[i].code,
              purchaseDate: dateUtils.formatDate(
                data[i].date_created,
                dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
                dateUtils.STATIC_FORMAT_DATE_TIME
              ),
              product: 'Voucher',
              totalPrice: this.getTotalPrice(data[i].items),
              status: data[i].status,
              productsList: data[i].items,
              deliveryInfo: data[i].delivery_info,
              paymentMethod: this.$t(functionUtils.paymentMethodTypeName(data[i].payment_method)),
              imageUrl: data[i].items[0].workpiece.image_url,
              voucherName: data[i].items[0].workpiece.name,
              totalQuantity: this.getTotalQuantity(data[i].items)
            }
            this.desserts.push(obj)
          }
          this.isLoading = false
        }.bind(this)
      )
    },
    onCancelOrderNotLogin: function () {
      let filter = {
        id: this.orderProductId
      }
      this.CANCEL_VOUCHER_PRODUCT_ORDER_NOT_LOGIN(filter).then(
        function () {
          this.getProductOrderNotLogin()
        }.bind(this)
      )
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getStoreChannelId: function () {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return StoreChannelType.PRODUCT_PORTAL
      }
      return this.storeChannelsIdInRouteParams()
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    getSettingConfig: async function () {
      let filter = {
        params: {
          getWithKey: true,
          key: stringUtils.ObjectSettingKey.DEFAULT_ENTITY_ID_FOR_MOBILE_APP
        }
      }
      await this.GET_OBJECT_SETTING(filter).then(
        function (res) {
          let data = res.data.data
          if (data.length !== 0) {
            this.agentDefaultId = parseInt(data[0].value)
          }
        }.bind(this)
      )
    },
    getPromotionPriceVoucher: function (voucherPrice, promotionValue, promotionType) {
      if (promotionType === VoucherProductPromotionType.PRICE) {
        return promotionValue
      } else if (promotionType === VoucherProductPromotionType.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    },
    getPromotionPriceOrder: function (voucherTotalPrice, promotionOrderList) {
      let result = 0
      promotionOrderList.forEach(e => {
        if (e.promotion_type === VoucherProductPromotionType.PRICE) {
          result += e.value
        } else if (e.promotion_type === VoucherProductPromotionType.PERCENT) {
          result += voucherTotalPrice * e.value / 100
        }
      })
      return result
    },
    ...mapActions([
      'GET_PRODUCT_ORDER',
      'CANCEL_VOUCHER_PRODUCT_ORDER',
      'GET_PRODUCT_ORDER_NOT_LOGIN',
      'CANCEL_VOUCHER_PRODUCT_ORDER_NOT_LOGIN',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss" scoped>
.no-space {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
